.site-footer {
	img.logo {
		width:auto;
		max-height:46px;
	}
	.footer-main {
		i,svg {
			min-width:30px;
			color:white;
		}
	}
	ul {
		list-style-type: none;
		margin: 0;
		padding: 0;
		li {
			padding-bottom:7.5px;
			&:last-of-type {
				padding-bottom:0;
			}
			a {
				color: white;
				text-decoration: none;
				font-weight: normal;
			}
		}
	}
	.gform_wrapper {
		.gform_description {
			margin-bottom:1rem;
			display:block;
		}
		.gfield_required_text {
			color:white;
		}
	}
	.gform_wrapper.gravity-theme .gform_footer button, .gform_wrapper.gravity-theme .gform_footer input, .gform_wrapper.gravity-theme .gform_page_footer button, .gform_wrapper.gravity-theme .gform_page_footer input {
		background:white;
		border:4px solid white;
		color:$brand-primary;
		&:hover {
			background:lighten($brand-primary,15%);
			border:4px solid lighten($brand-primary,15%);
			color:white;
		}
	}
	ul#menu-footer-first-menu li {
		display: inline-block;
		min-width: 54px;
		float: left;
		margin-right: 30px;

		&:nth-child(3n) {
			clear:both;
		}
	}
	img.logo {
		filter: brightness(0) invert(1);
	}
}
 #gform_2 .gform_footer.top_label {
    padding: 0px!important;
    margin: 0;
}
.get-rewards {
	margin-top:-59px;
}
.footer-app-image {
	width:auto;
	height:50px;
	@include media-breakpoint-up(lg) {
		height: 65px;
		margin-top: 11px;
	}
	@include media-breakpoint-up(xl) {
		height:52px;
		margin-top:auto;
	}
	@include media-breakpoint-up(xxl) {
		height:75px;
	}
}