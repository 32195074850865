// Theme Colors/Branding
$brand-primary: #21BD30;
$brand-primary-rgb: 0,0,0;
$brand-primary-offset: #000;
$brand-secondary: #000;
$dark: #000;
$light: #DFE1DF;
$medium: #dee2e6;
$inactive: #8c8c8c;
$gray: #888;
$white: #fff;
$accent: #FFD500;
$accent-2: #00BFEC;
$color-yellow: #FFD500;
$color-blue: #00BFEC;
$color-orange: #FB9100;
$color-red: #E44B55;
$body-font: #fff;
$error: #E44B55;