/** Search form */
.search-form {
  @extend .row;
}

.search-form label {
  @extend .mb-3;

  font-weight: normal;
}

.search-form .search-field {
  @extend .form-control;
}

.search-form .search-submit {
  @extend .btn;
  @extend .btn-secondary;
}

/* Gravity Forms Styling */

.gform_legacy_markup_wrapper textarea.large {
  @include media-breakpoint-up(md) {
    height:150px!important;
  }
}

.gform_wrapper.gravity-theme .gfield input.large, .gform_wrapper.gravity-theme .gfield select.large, textarea {
  background: #fff !important;
  border-radius: 10px !important;
  padding: 10px;
  color: #21BD30;
  font-family: "Avenir Next Heavy", Helvetica, Arial, sans-serif;
}
.no-label label {
  display: none!important;
}
#gform_2 input[type="text"] {
  background: #fff!important;
  border-radius: 10px!important;
  padding: 11px;
  font-size: 13px;
  color: #21BD30;
  font-family: "Avenir Next Heavy", Helvetica, Arial, sans-serif;
  border:0px;

  &::placeholder {
    color: #21BD30;
    font-family: "Avenir Next Heavy", Helvetica, Arial, sans-serif;
  }
}
#gform_2 {
  .gform_wrapper.gravity-theme .gform_footer, .gform_wrapper.gravity-theme .gform_page_footer {
    margin: 0px;
    padding: 0px;
  }
  .gform_footer {
    position:relative!important;
  }
}
input#gform_submit_button_2 {
  color:#fff;
  background: #21BD30;
  padding: 14px 15px 10px;
  width: 100%;
  border-radius: 0px 10px 10px 0px;
  border: 0;
  font-family: "Avenir Next Heavy", Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  position: absolute;
  width: 120px;
  line-height: 1.25em;
  top: -46px;
  right: 0px;
  height:46px;
}
@media (max-width:641px) {
  input#gform_submit_button_2 {
    top:-48px;
    height:48px;
  }
}
#gform_4 input[type=text], #gform_5 input[type=text], #gform_6 input[type=text] {
  background: #fff !important;
  border-radius: 10px !important;
  border: 0px !important;
  padding: 10px;
  color: #21BD30;
  font-family: "Avenir Next Heavy", Helvetica, Arial, sans-serif;

  &::placeholder {
    color: #21BD30;
  }
}
input#gform_submit_button_4, #gform_submit_button_5, input#gform_submit_button_6  {
  color: #fff;
  background: #21BD30;
  padding: 10px 15px;
  width: 100%;
  border-radius: 10px;
  border: 0;
  font-family: "Avenir Next Heavy", Helvetica, Arial, sans-serif;
  text-transform: uppercase;
}

// #gform_2 input[type=text] {
//   background: #fff !important;
//   border-radius: 10px !important;
//   padding: 10px;
//   color: #21BD30;
//   font-family: "Avenir Next Heavy", Helvetica, Arial, sans-serif;
// }

.gform_wrapper.gravity-theme .gform_footer, .gform_wrapper.gravity-theme .gform_page_footer {
  margin: 6px 0 0;
  padding: 16px 0 !important;
}

