// Container Fluid Adjustments
.container-fluid {
  padding-left: 25px;
  padding-right: 25px;
  @include media-breakpoint-up(md) {
    padding-left: 40px;
    padding-right: 40px;
  }
  @include media-breakpoint-up(xl) {
    padding-left: 72px;
    padding-right: 72px;
  }
}