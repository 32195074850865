.offcanvas {
  background-attachment:fixed!important;

  &.show {
    width:100vw;
  }
  .btn-close {
    opacity: 1;
    font-size: 1.5em;
  }
  .navbar-nav {
    .nav-link {
      color:white;
      font-weight:bold;
      padding-top:1rem;
      padding-bottom: 2rem;
      font-size: 1.75em;
      font-family: $heading-font;
    }
    .dropdown-menu {
      position:relative!important;
      transform:none!important;
      border:0;
      background:transparent;

      .nav-item {
        .dropdown-item {
          color:white;
          text-align: center;
          padding-bottom: 2rem;
          font-size: 1.75em;
          font-family: $heading-font;

          &:hover {
            color:white;
            opacity: .8;
          }
        }
      }
    }
  }
}