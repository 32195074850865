body {
  background: transparent;
  overflow-x: hidden;
}
html {
  /*background:url(../../images/mega-bud-bkg.jpg);
  background-size: cover;*/
  background: #000;
}
.mega-bud-bkg {
  background:url(../../images/mega-bud-bkg.jpg);
  background-size: cover;
}
.main *::selection {
  color: $white;
  background: $brand-primary;
}

.main *::selection {
  color: $white;
  background: $brand-primary;
}

// Safari Fixes

// phone link
a[href^=tel] {
  text-decoration: inherit;
  color: inherit;
}

//aos
[data-aos*=flip] {
  -webkit-backface-visibility:hidden!important;
}

// Accessibility Plugin Adjustments

#pojo-a11y-toolbar {
  height: 100%;
  top: 0!important;
}

.pojo-a11y-toolbar-toggle {
  bottom: 0!important;
}

.pojo-a11y-toolbar-overlay {
  position: absolute!important;
  bottom: 0!important;
  right: 0!important;
}

/** custom classes */
.ratio-21x9 {
  aspect-ratio: 21 / 9!important;
}
.ratio-hero {
  aspect-ratio: 1629 / 1013!important;
}
.splide__track.enable-scroll {
  margin-left:0rem;
  margin-right:0rem;
  overflow: scroll;
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
}
.container-fluid.cp-0 {
  padding-left: 0px;
  padding-right: 0px;
  .row {
    margin-left: 0px;
    margin-right: 0px;
  }
}
.row.slide-x {
  flex-wrap: nowrap;
  overflow: scroll;
}
.border-radius {
  border-radius: 15px;
  overflow: hidden;
}
table.border-radius {
  border-radius: 0px;

  tr:nth-child(2) {
    td:first-child {
      border-radius: 12px 0px 0px 0px;
    }
    td:last-child {
      border-radius: 0px 12px 0px 0px;
    }

  }
  tr:last-child {
    td:first-child {
      border-radius: 0px 0px 0px 12px;
    }
    td:last-child {
      border-radius: 0px 0px 12px 0px;
    }
  }
}
.background-fixed {
  @include media-breakpoint-up(lg) {
    background-attachment: fixed !important;
  }
}

.cursor-hover {
  &:hover {
    cursor:pointer;
  }
}

.videoWrapper {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 */
  height: 0;
}

.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.min-height {
  min-height: 50vw;
  @include media-breakpoint-up(md) {
    min-height: 33vw;
  }
  @include media-breakpoint-up(lg) {
    min-height: 350px;
  }
}

hr {
  background-color:rgba(0,0,0,0.5);
}

.square {
  padding-top: 100%;
}

.overflow-hidden {
  overflow: hidden!important;
}

.absolute {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
}

.light-box-shadow {
  box-shadow: 0 20px 20px -10px rgba(0, 0, 0, 0.25);
}

.min-height-smaller {
  min-height: 33vh;
  @include media-breakpoint-up(md) {
    min-height: 300px;
  }
}

.min-height-md-down {
  @include media-breakpoint-down(md) {
    min-height: 400px;
  }
}

.pad-top-100 {
  padding-top: 100%!important;
}

.circle {
  padding: 0;
  border-radius: 1000px;
  z-index: 2;
  border: solid 5px $brand-primary;
}

.large-icon * {
  font-size: 2.5em;
  @include media-breakpoint-up(md) {
    font-size: 3em;
  }
  @include media-breakpoint-up(md) {
    font-size: 4em;
  }
}

.grow-bkg {
  transition: all 0.2s ease-in;

  &.grow-this-bkg,
  .grow-this-bkg {
    background-size: 100%!important;
    transition: all 0.2s ease-in;

    &:hover {
      transition: all 0.2s ease-in;
      background-size: 110%!important;
    }
  }
}

.circle {
  padding: 0;
  border-radius: 1000px;
  z-index: 2;
  border: solid 5px $brand-primary;

  &.small-bio {
    padding: 30px;
    border: solid 5px #fff;
  }
}

.notunder1500 {
  display: none;
}
@media (min-width:1800px) {
  .notunder1500 {
    display: block;
  }
}

.negative-top {
  margin-top: -100px;
}

.negative-top-header {
  margin-top: -47px;
  @include media-breakpoint-up(md) {
    margin-top: -115px;
  }
  @include media-breakpoint-up(lg) {
    margin-top: -130px;
  }
}

.clip-top {
  clip-path: polygon(0px 25px, 100% 0, 100% 100%, 0px 100%);
}

.clip-oblique {
  clip-path: polygon(0px 0px, 100% 3%, 100% 97%, 0px 100%);
  @include media-breakpoint-up(lg) {
    clip-path: polygon(0px 0px, 100% 8%, 100% 92%, 0px 100%);
  }
}

.border-bottom-trans-white {
  border-bottom: solid 1px rgba(255,255,255,.1);
}

.box-shadow {
  box-shadow: 0 0 30px rgba(0,0,0,0.1);
}

.box-shadow-small {
  box-shadow: 0 0 15px rgba(0,0,0,0.1);
}
// Images

.img-wrap {
  img {
    max-height: 75px;
    margin: 10px;
  }
}

img {
  max-width: 100%;
}

.img-on-dark-bg {
  filter: brightness(0) invert(1);
}
// Util

.absolute-link {
  position: absolute;
  z-index: 99999999!important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.absolute-positioner {
  top: 0;
  left: 0;
}

.opacity-soft {
  opacity: 0.97;
}

.opacity-75 {
  opacity: 0.75;
}

.opacity-85 {
  opacity: 0.85;
}

.half-opacity {
  opacity: 0.5;
}

.grayscale {
  filter: grayscale(1);
}

.z-index-1 {
  z-index:1;
}

.z-index-100 {
  z-index:100;
}

.canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(45deg, $dark, darken($dark, 10%));
  opacity: 0.85;

  &.light-fade {
    background: linear-gradient(45deg, #000, lighten(#000,10%), #000);
    opacity: 0.6;
  }
}

.sticky {
  @include media-breakpoint-up(md) {
    position: sticky !important;
    top: 160px !important;
  }
}
// Content Images

.img-cover-col {
  background-size: cover;
  background-position: center;
  @include media-breakpoint-down(sm) {
    display: none !important;
  }
}
// RESPONSIVE FIXES

.sticky-top {
  @include media-breakpoint-down(sm) {
    position: static;
  }
}

// Move accessibility button to bottom

.pojo-a11y-toolbar-toggle {
  bottom: 0;
}
/** Landing Page table **/
.word-break-all {
  word-break: break-all;
}

.white-bkg {
  background: #fff;
}

.border-radius {
  border-radius: 12px;
  border-collapse: separate;
}

.table-box-shadow {
  box-shadow: 0px 20px 10px rgba(0, 0, 0, 0.15);
}

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  padding-bottom: 30px;
  background-color: transparent;
  display: table;

  &.img-max-100 {
    img {
      max-height: 100px;
      width: auto;
    }
  }
}

.table-row {
  display: table-row;
}

.table-cell {
  display: table-cell;
  padding: 0px 5px;
}

.icon-circle {
  position: absolute;
  top: -45px;
  height: 75px;
  width: 75px;
  border-radius: 75px;
  border: solid 3px;
  display: inline-block;
  left: 0px;
  right: 0px;
  margin: auto;
  z-index: 2;
}

.td-text-center {
  text-align: center;
  display: block;
}
@media (max-width:767px) {
 .td-text-center {
  width: calc(100% - 125px);
 }
}
.dot {
  padding:15px;
}

.dot .primary-btn {
  font-size: 16px;
  opacity: 1 !important;
  color: #fff;
}

@media (min-width:768px) {
  .t-40 {
    width: 50%;
  }

  .t-15 {
    width: 11.667%;
  }
}

.larger {
  font-size: 1.1em !important;
}

.teal {
  color: #36c3e0;
}
.leaf-icon {
  max-height: 40px;
  max-width: 40px!important;
  filter: brightness(0) invert(1);
}
.page-template-template-landing-page .h2,
.page-template-template-landing-page .h3 {
  text-transform: uppercase;
   /* background: -webkit-linear-gradient(left, #27b9f7, #001b60);
   background: -o-linear-gradient(right, #27b9f7, #001b60);
   background: -moz-linear-gradient(right, #27b9f7, #001b60);
   background: linear-gradient(to right, #27b9f7, #001b60);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
}
.page-template-template-landing-page table {
  border-collapse: collapse;
  margin: 0;
  padding: 0px;
  padding-bottom: 15px;
  width: 100%;
  table-layout: fixed;
}
@media (max-width:767px) {
  .page-template-template-landing-page table {
    display: none;
  }
}
@media (min-width:768px) {
  .d-md-none {
    display: none;
  }
}
.page-template-template-landing-page table caption {
  font-size: 1.5em;
  margin: .5em 0 .75em;
}

.page-template-template-landing-page table tbody {
  display: table;
  border-collapse: collapse;
  width: calc(100% - 20px);
  margin-left: 10px;
  padding-bottom: 30px;
}

.page-template-template-landing-page table tr {
  background-color: #d1d4d5;
  border: 1px solid #ddd;
}

@media (min-width:768px) {
  .page-template-template-landing-page table tr {
    padding: .35em;
  }
}

.page-template-template-landing-page table th,
.page-template-template-landing-page table td {
  padding: .33em;
  background: #fff;
  border-bottom: solid 1px #999 !important;
  border-collapse: collapse !important;
  border-left: solid 0px #fff;
  border-right: solid 0px #fff;
}

@media (min-width:768px) {

  .page-template-template-landing-page table th,
  .page-template-template-landing-page table td {
    padding: .33em;
    border-left: solid 15px #fff;
    border-right: solid 15px #fff;
  }
}

@media (min-width:992px) {

  .page-template-template-landing-page table th,
  .page-template-template-landing-page table td {
    border-left: solid 25px #fff;
    border-right: solid 25px #fff;
  }
}
@media (min-width:767px) {
  .page-template-template-landing-page table tr:last-child td {
    border-color: #fff !important;
  }
}

.page-template-template-landing-page table th {
  font-size: .85em;
  letter-spacing: .1em;
  text-transform: uppercase;
  position: relative;

}

@media screen and (min-width: 767px) {
  .page-template-template-landing-page table th {
    padding-top: 30px;
  }
}

@media screen and (max-width: 767px) {
  .page-template-template-landing-page table {
    border: 0;
  }

  .page-template-template-landing-page table caption {
    font-size: 1.3em;
  }

  .page-template-template-landing-page table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  .page-template-template-landing-page table tr {
    background-color: #fff;
    border: 0px !important;
    margin-bottom: 15px;
    position: relative;
    display: block;
  }

  .page-template-template-landing-page table td {
    display: block;
    font-size: .8em;
    text-align: left;
  }

  .page-template-template-landing-page table td::before {
    /*
  * aria-label has no advantage, it won't be read inside a table
  content: attr(aria-label);
  */
    content: attr(data-label);
    float: right;
    font-weight: bold;
    text-transform: uppercase;
    padding-right: 10px;
    color: #fff;
  }

  .page-template-template-landing-page table td:last-child {
    border-bottom: 0;
  }

  .page-template-template-landing-page table th {
    display: none;
  }

  .page-template-template-landing-page td.service-2 {
    background: #dcdcdc;
    border-radius: 5px 5px 0px 0px;
    margin-top: 10px!important;
    line-height: 1.25em;
    padding-bottom: 10px .33em;
  }
}

@media (min-width:768px) {
  .d-none.d-md-row {
    display: table-row !important;
  }
}

.page-template-template-landing-page table td .primary-btn {
  margin: 5px;
  padding: .5em 1em;
  display: block !important;
  cursor:default!important;
  box-shadow: none!important;
}
.page-template-template-landing-page table td .primary-btn:hover {
  box-shadow: none!important;
}
.primary-btn.larger {
  font-size: 1.5em;
}

@media (max-width:767px) {
  .page-template-template-landing-page table td .primary-btn {
    display: inline-block !important;
  }
  .text-center-sm, .text-center-sm * {
    text-align: center;
  }
}
.page-template-template-landing-page .navbar-nav,
.page-template-template-landing-page button.navbar-toggler,
.page-template-template-landing-page .header-style-3-topbar {
    display: none!important;
}
.dark-grey {
  background: #b2b6b7;
}

.basic-grad:before,
.premier-grad:before,
.enterprise-grad:before {
  content: '';
  height: 15px;
  border-radius: 10px 10px 0px 0px;
  position: absolute;
  top: -15px;
  left: 0px;
  width: 100%;
}

.basic-grad,
.basic-grad:before {
  background: linear-gradient(90deg, #ffaa00, #c88609) !important;
}

.basic-border {
  border-color: #ffaa00;
}

.premier-grad,
.premier-grad:before {
  background: linear-gradient(90deg, #666666, #222222) !important;
}

.premier-border {
  border-color: #666666;
}

.enterprise-grad,
.enterprise-grad:before {
  /*background: linear-gradient(90deg, #194671, #052033) !important;*/
    background: linear-gradient(90deg, #666666, #222222) !important;
}

.enterprise-border {
  border-color: #666666;
}

td.basic-2::before  {
  color: #60a65b;
}
.basic-color {
  color: #60a65b;
}
td.premier-2:before {
  color: #00b7d9;
}
.premier-color {
  color: #00b7d9;
}
td.enterprise-2:before {
  color: #194671;
}
.enterprise-color {
  color: #00b7d9;
}

.white,
.white * {
  color: #fff !important;
}

@media (max-width:767px) {
  .page-template-template-landing-page table tr.cost-rows {
    display: none;
  }

  .page-template-template-landing-page table tr.cost-rows.show {
    display: block;
  }

  .page-template-template-landing-page table tr.cost-rows:last-child {
    display: block !important;
  }
}

@media (min-width:768px) {
  .page-template-template-landing-page table tr.cost-rows {
    display: table-row;
  }
}
@media (min-width:992px) {
  .text-center-lg-up, .text-center-lg-up * {
    text-align: center;
  }
}
.page-template-template-landing-page .modal-backdrop.show {
  display: none!important;
}
.page-template-template-landing-page .modal-content {
  box-shadow: none;
  background: transparent;
}
.page-template-template-landing-page .modal-body {
  max-height: 90vh;
  overflow:scroll;
}
#ctaModal .content-form {
  box-shadow:0 0 15px 1px rgb(0,0,0,.1);
}
#ctaModal .close-btn {
  background-color: #194671;
  color: #fff;
  border: 0px;
  height: 50px;
  width: 50px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 25px;
  margin-bottom: 15px;
  font-size: 25px;
}
.mobile-row {
  border-bottom: solid 1px #999;
  margin: auto 15px;
  padding: 10px 10px 10px;
  line-height: 1.25em;
}

.gradient-text {
   background: -webkit-linear-gradient(left, #27b9f7, #001b60);
   background: -o-linear-gradient(right, #27b9f7, #001b60);
   background: -moz-linear-gradient(right, #27b9f7, #001b60);
   background: linear-gradient(to right, #27b9f7, #001b60);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@media (min-width: 992px){
  .process-single-header-row{
    padding: 4rem 0 !important;
  }
}
.modal .modal-content {
  background:#000!important;
  color:#fff!important;
}
.modal .btn-close {
  filter: brightness(1) invert(1);
}

//scrollbars
/*::-webkit-scrollbar {
  max-width: 8px;
}*/
/* Track */
::-webkit-scrollbar-track {
  background: $dark;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $dark;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $dark;
}
::-webkit-scrollbar-thumb:horizontal,
::-webkit-scrollbar-track:horizontal {
    background: transparent;
    display: none!important;
    height: 0px;
    widows: 0px;
}
.p-0-xs, .p-0-xs .col-12, .p-0-xs .row {
  @include media-breakpoint-down(sm) {
    padding: 0;
    margin: 0;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0,0,0,0);
  border: 0;
}

.splash #topLevelNav, .splash .site-footer {
  display:none;
}

.splash {
  background: url(../../images/mega-bud-bkg.jpg) center center / cover;
}

.splash {
    min-height:100vh;
}
