/*
** font sizes calculated in perfect fourths using https://type-scale.com/
*/

@font-face {
  font-family: 'Avenir Next';
  src: url('../../fonts/AvenirNextLTPro-Regular.woff2') format('woff2'),
       url('../../fonts/AvenirNextLTPro-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir Next Heavy';
  src: url('../../fonts/AvenirNextLTPro-Bold.woff2') format('woff2'),
       url('../../fonts/AvenirNextLTPro-Bold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}


// set type
$menu-font: 'Avenir Next', Helvetica, Arial, sans-serif;
$heading-font: 'Avenir Next Heavy', Helvetica, Arial, sans-serif;
$subheading-font: 'Avenir Next', Helvetica, Arial, sans-serif;
$paragraph-font: 'Avenir Next', Helvetica, Arial, sans-serif; // set weights
$light-weight: 300;
$normal-weight: 400;
$medium-weight: 600;
$heavy-weight: 700;
$ultra-weight: 900;

body {
  font-size: 16px;
  line-height: 1.5rem;
  color: $body-font;
  font-family: $paragraph-font;
  @include media-breakpoint-up(md) {
    font-size: 1.125rem; // 18px
  }
}

a {
  color: $brand-primary;
  font-weight: $heavy-weight;
  text-decoration: none;
  transition-duration: 350ms;
  -webkit-transition-duration: 350ms;
  -moz-transition-duration: 350ms;

  &:hover {
    text-decoration: none;
    color: $brand-secondary;
  }
}

.bold,
strong {
  font-weight: 700;
}

.sub-heading {
  font-size: 1.25rem;
  font-family: $subheading-font;
  font-weight: 700;
  text-transform: uppercase;
  display: inline-block;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $heading-font;
  margin-top: 0;
  margin-bottom: 1rem;
}

.h1,
h1 {
  font-weight: $heavy-weight;
  font-size: 2.369rem;
  @include media-breakpoint-up(lg) {
    font-size: 3.157rem;
  }
}
.h2,
h2 {
  font-weight: $heavy-weight;
  font-size: 1.5rem;
  @include media-breakpoint-up(lg) {
    font-size: 2.369rem;
  }
}

.h3,
h3 {
  font-weight: $heavy-weight;
  font-size: 1.25rem;
  @include media-breakpoint-up(xl) {
    font-size: 1.777rem;
  }
}
.h3-md-1em h3 {
  @include media-breakpoint-only(md){
    font-size: 1rem;
  }
}
.h4,
h4 {
  font-size: 1rem;
  font-weight: $heavy-weight;
  @include media-breakpoint-up(lg) {
    font-size: 1.333rem;
  }
}

.h5,
h5 {
  font-size: 1rem;
  font-weight: $normal-weight;
  @include media-breakpoint-up(lg) {
    font-size: 1.25rem;
  }
}
// Line height

.reduce-line-height {
  line-height: 1 !important;
}
.address {
  color: #00BFEC;
  font-family: $heading-font;
  font-size: 1.25em;
}
.open-date {
  color: #21BD30;
  font-family: $heading-font;
  font-size: 1.5em;
  text-transform: uppercase;
}
.deal {
  color: #FFD500;
  font-family: $heading-font;
  font-size: 1.5em;
}
.small {
  font-size: 14px;
}
.reward {
  color:#FB9100;
  font-family: $heading-font;
  font-size: 1.5em;
}
.opening {
  color: #21BD30;
  font-family: $heading-font;
  font-size: 1.5em;
}
.start {
  font-family: $heading-font;
  font-size: 1.25em;
}
.btn-yellow {
  color: #fff;
  background-color: #FFD500;
  border-radius: 10px;
  padding: 15px;
  display: block;
  font-family: $heading-font;
}
.btn-green {
  color: #fff;
  background-color: #21BD30;
  border-radius: 10px;
  padding: 15px;
  display: block;
  font-family: $heading-font;
}
.uppercase {
  text-transform: uppercase!important;
}